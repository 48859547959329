import { fetch500MbHeightContours, fetch500mbWindspeedContours, fetchMSLPContours } from "../API";

export const generateNWPSource = async map => {
    const gpm = await fetch500MbHeightContours();
    const mslp = await fetchMSLPContours();
    const ws500mb = await fetch500mbWindspeedContours();

    const myGpm = map.addSource('gpm',{ 'type': 'geojson', 'data': gpm });
    const myMSLP = map.addSource('mslp',{ 'type': 'geojson', 'data': mslp });
    const my500mbWS = map.addSource('500mbWS',{ 'type': 'geojson', 'data': ws500mb });
}

