export const getSatInfrared = (visibility, dayNight) => {
    return (
        {
            id: `satIr108`,
            type: 'raster',
            source: 'ir108',
            paint: {
                "raster-opacity": 0.7,
                "raster-saturation": -1,
                // "raster-contrast": -0.15
            },
            minzoom: 0,
            maxzoom: 12,
            layout: {
                visibility: visibility
            }
        }
    );
};

export const getSatWv = (visibility, dayNight) => {

    console.log(visibility);

    return (
        {
            id: `satWv`,
            type: 'raster',
            source: 'irWv',
            paint: {
                "raster-opacity": 0.5,
                // "raster-saturation": -1,
                "raster-contrast": 0.25
            },
            minzoom: 0,
            maxzoom: 12,
            layout: {
                visibility: visibility
            }
        }
    );
}

export const getSatVis = (visibility, dayNight) => {

    console.log(visibility)

    return (
        {
            id: `satVis`,
            type: 'raster',
            source: 'satVis',
            paint: {
                "raster-opacity": 0.6,
                "raster-contrast": 0.15
            },
            minzoom: 0,
            maxzoom: 12,
            layout: {
                visibility: visibility
            }
        }
    );
}