import { setIntervalSatellite } from "./sat/interval";
import { setLightningIntervals } from "./lightning/interval";
import { setIntervalsCapeObsFetcher } from "./obs/interval";

import {
    setIntervals500mbWindFetcher,
    setIntervalsGPMFetcher,
    setIntervalsMSLPFetcher
} from "./nwp/interval";

export const generateIntervals = async (map, liveLightningGeojson, centerPointHistory) => {
    setIntervalSatellite(map);
    setLightningIntervals(map, liveLightningGeojson, centerPointHistory);
    setIntervalsGPMFetcher(map);
    setIntervalsMSLPFetcher(map);
    setIntervals500mbWindFetcher(map);
    setIntervalsCapeObsFetcher(map)
}

