export const createSbCapeLayer = () => {
    return {
        'id': 'sbcape',
        'type': 'fill',
        'source': 'sbcape',
        'paint': {
            'fill-color': [
                'case',
                ['==', ['get', 'title'], '0.00-100.00 J/kg'],  // Check if the title is "0.00-100.00 J/kg"
                'rgba(0,0,0,0)',  // Transparent color for this title
                ['==', ['get', 'title'], '100.00-200.00 J/kg'],  // Check if the title is "0.00-100.00 J/kg"
                'rgba(0,0,0,0)',  // Transparent color for this title
                ['get', 'fill']  // Otherwise, use the 'fill' property from feature properties
            ],
            'fill-opacity': 0.4
        },
        'layout': {
            'visibility': window.localStorage.getItem('capeVisibility')
                ? window.localStorage.getItem('capeVisibility')
                : 'visible'
        }
    };
};