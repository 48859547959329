import { fetchCape } from "./API";

export const setIntervalsCapeObsFetcher = map => {
    const now = new Date();
    const nextHour = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), 30, 0, 0);
    const difference = nextHour - now;

    window.setTimeout(async () => {
        const sbCape = await fetchCape();
        await map.getSource('sbcape').setData(sbCape);

        setInterval(async () => {
            const sbCape = await fetchCape();
            await map.getSource('sbcape').setData(sbCape);
        }, 3600000);

    }, difference);
}