import { generateLightningSource } from "./lightning/source/source";
import { generateNWPSource } from "./nwp/source/source";
import { generateRadarSource } from "./radar/source/source";
import { generateShootingLocationSource } from "./markers/source/source";
import { generateSatelliteSource } from "./sat/source/source";
import {generateObsSources} from "./obs/source/source";

export const generateMapSources = async (map, liveLightningGeojson) => {

    await generateLightningSource(map, liveLightningGeojson);
    await generateNWPSource(map);
    await generateRadarSource(map);
    // generateShootingLocationSource(map)
    generateSatelliteSource(map);
    await generateObsSources(map);
};