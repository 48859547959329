export const createGPM500Layer = dayNight => {
    return {
        'id': '500mbHeightLayer',
        'type': 'line',
        'source': 'gpm',
        'paint': {
            'line-color': dayNight === "day"
                ? 'rgba(0,139,220,0.8)'
                : 'rgba(255,255,0,0.4)'
            ,
            'line-width': dayNight === 'day' ? 3 : 1.25
        },
        'layout': {
            'visibility': window.localStorage.getItem('nwpVisibility')
                ? window.localStorage.getItem('nwpVisibility')
                : 'visible'
        }
    };
};

export const createWS500Layer = dayNight => {
    return {
        'id': '500mbWindspeedFillLayer',
        'type': 'fill',
        'source': '500mbWS',
        'paint': {
            // 'line-color': dayNight === "day"
            //     ? 'rgba(0,0,0,0.3)'
            //     : 'rgba(255,255,0,0.8)'
            // ,
            'fill-color': {
                "property": "value",
                "stops": [
                    [49, `${dayNight === "day" ? 'rgba(72,255,0,0.0)' : 'rgba(255,100,255,0.0)'}`], // 0
                    [50, `${dayNight === "day" ? 'rgba(72,255,0,0.1)' : 'rgba(255,100,255,0.1)'}`], // 0.1
                    [60, `${dayNight === "day" ? 'rgba(72,255,0,0.15)' : 'rgba(255,100,255,0.15)'}`], // 0.15
                    [70, `${dayNight === "day" ? 'rgba(72,255,0,0.20)' : 'rgba(255,100,255,0.20)'}`], // 0.25
                    [80, `${dayNight === "day" ? 'rgba(72,255,0,0.25)' : 'rgba(255,100,255,0.25)'}`],
                    [90, `${dayNight === "day" ? 'rgba(72,255,0,0.3)' : 'rgba(255,100,255,0.30)'}`]
                ]
            },
        },
        'layout': {
            'visibility': window.localStorage.getItem('500mbWindVisibility')
                ? window.localStorage.getItem('500mbWindVisibility')
                : 'visible'
        }
    };
};
export const createWS500LayerContours = dayNight => {
    return {
        'id': '500mbWindspeedLayer',
        'type': 'line',
        'source': '500mbWS',
        'paint': {
            // 'line-color': dayNight === "day"
            //     ? 'rgba(0,0,0,0.3)'
            //     : 'rgba(255,255,0,0.8)'
            // ,
            'line-color': {
                "property": "value",
                "stops": [
                    [49, `${dayNight === "day" ? 'rgba(72,255,0,0.7)' : 'rgba(255,100,255,0.7)'}`],
                    [50, `${dayNight === "day" ? 'rgba(72,255,0,0.7)' : 'rgba(255,100,255,0.7)'}`],
                    [60, `${dayNight === "day" ? 'rgba(72,255,0,0.7)' : 'rgba(255,100,255,0.7)'}`],
                    [70, `${dayNight === "day" ? 'rgba(72,255,0,0.7)' : 'rgba(255,100,255,0.7)'}`],
                    [80, `${dayNight === "day" ? 'rgba(72,255,0,0.7)' : 'rgba(255,100,255,0.7)'}`],
                    [90, `${dayNight === "day" ? 'rgba(72,255,0,0.7)' : 'rgba(255,100,255,0.7)'}`],
                ]
            },
            'line-width': {
                "property": "value",
                "stops": [
                    [49, 0],
                    [50, 1],
                    [60, 1.25],
                    [70, 1.5],
                    [80, 1.75],
                    [90, 2]
                ]
            }
        },
        'layout': {
            'visibility': window.localStorage.getItem('500mbWindVisibility')
                ? window.localStorage.getItem('500mbWindVisibility')
                : 'visible'
        }
    };
};
export const createGPMlabelLayer = dayNight => {
    return {
        'id': 'gpmlabel',
        'type': 'symbol',
        'source': 'gpm',
        'layout': {
            'symbol-placement': 'line-center',
            'text-field': ['concat', ['to-string', ['get', 'name']], 'm'],
            'visibility': window.localStorage.getItem('nwpVisibility')
                ? window.localStorage.getItem('nwpVisibility')
                : 'visible'
            // 'text-offset': [1,-0.5],
            // 'text-size': 12
        },
        'paint': {
            'text-color': dayNight === "day"
                ? 'rgba(0,139,220,0.8)'
                : 'rgba(255,255,0,0.4)'
            ,
        }
    };
};
export const createMSLPLayer = dayNight => {
    const colorClear = dayNight === "day" ? 'rgba(0,0,0,0.8)' : 'rgba(0,200,255,0.5)';
    const colorFaint = dayNight === "day" ? 'rgba(0,0,0,0.2)' : 'rgba(0,200,255,0.15)';

    const clearValues = [
        '1060', '1056', '1052', '1048', '1044', '1040', '1038', '1034',
        '1030', '1026', '1022', '1018', '1016', '1012', '1008', '1004',
        '1000', '996', '992', '988', '984', '980', '976', '972', '968',
        '964', '960', '956', '952', '948', '944', '940'
    ];

    return {
        'id': 'mslpLayer',
        'type': 'line',
        'source': 'mslp',
        'paint': {
            'line-color': [
                'match',
                ['get', 'name'],
                ...clearValues.flatMap(value => [value, colorClear]),
                colorFaint // Default color
            ],
            'line-width': 1,
            'line-dasharray': [3, 1],
        },
        'layout': {
            'visibility': window.localStorage.getItem('mslpVisibility')
                ? window.localStorage.getItem('mslpVisibility')
                : 'visible'
        }
    };
};

export const createMSLPlabelLayer = dayNight => {
    // Array of values to show labels for
    const showLabelValues = [
        '1060', '1056', '1052', '1048', '1044', '1040', '1038', '1034', '1030', '1026',
        '1022', '1018', '1016', '1012', '1008', '1004', '1000', '996', '992', '988',
        '984', '980', '976', '972', '968', '964', '960', '956', '952', '948', '944', '940'
    ];

    return {
        'id': 'mslpLabel',
        'type': 'symbol',
        'source': 'mslp',
        'layout': {
            'symbol-placement': 'line-center',
            'text-field': ['concat', ['to-string', ['get', 'name']], 'mb'],
            'visibility': window.localStorage.getItem('mslpVisibility')
                ? window.localStorage.getItem('mslpVisibility')
                : 'visible'
            // 'text-offset': [1,-0.5],
            // 'text-size': 12
        },
        'paint': {
            'text-color': dayNight === "day"
                ? 'rgba(0,0,0,0.3)'
                : 'rgba(0,200,255,0.5)'
        },
    };
};
